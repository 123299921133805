import HoverBoxComponent from '@wix/thunderbolt-elements/src/components/MediaContainers/HoverBox/viewer/HoverBox';


const HoverBox = {
  component: HoverBoxComponent
};


export const components = {
  ['HoverBox']: HoverBox
};

